import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import moment from 'moment';

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: this.$t('etat_article_detail.page_titre'),
            point_de_ventes_id: "",
            point_de_ventes: "",
            montant_total: 0,
            remise_total: 0,
            vuetable: {
                moreParams: {
                    type_document: "",
                },
                fields: [
                    // {
                    //     name: VuetableFieldCheckbox,
                    //     titleClass: 'text-center',
                    //     dataClass: 'text-center'
                    // },

                    {
                        name: 'nom_point_vente',
                        title: this.$t('etat_article_detail.point_de_vente'),
                        dataClass: 'text-left',
                        sortField: 'nom_point_vente',
                    },
                    {
                        name: 'ref_article',
                        title: this.$t('etat_article_detail.reference'),
                        dataClass: 'text-left',
                        sortField: 'ref_article',
                    },
                    {
                        name: 'designation',
                        title: this.$t('etat_article_detail.designation'),
                        dataClass: 'text-left',
                        sortField: 'designation',
                    },

                    {
                        name: 'quantite',
                        title: this.$t('etat_article_detail.quantite'),
                        dataClass: 'text-right',
                        sortField: 'quantite',
                        titleClass: 'text-center',
                    },
                    {
                        name: 'pau_ttc',
                        title: this.$t('etat_article_detail.pu_vente'),
                        dataClass: 'text-right',
                        sortField: 'pau_ttc',
                        titleClass: 'text-center',
                    },
                    {
                        name: 'remise',
                        title: this.$t('etat_article_detail.remise'),
                        dataClass: 'text-right',
                        sortField: 'remise',
                        titleClass: 'text-center',
                        width: '170px',
                    },
                    {
                        name: 'montant_total',
                        title: this.$t('etat_article_detail.montant'),
                        dataClass: 'text-right',
                        sortField: 'montant_total',
                        titleClass: 'text-center',
                        width: '200px'
                    },


                ],
                sortOrder: [
                    { field: 'articles_id', direction: 'asc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Point de vente': 'nom_point_vente',
                    'Référence': 'ref_article',
                    'Désignation': 'designation',
                    'Quantité': 'quantite',
                    'Prix unitaire': 'pau_ttc',
                    'Remise': 'remise',
                    'Montant': 'montant_total'
                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
                exportLink: this.BASE_URL + "/factures/fetchexport",
            },
            datepicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            motCle: "",
            crudform: {

            },
            crudmodaltitle: "Nouveau Dégâts (rebut)",
            listdata: {
                magasins: [],
                magasin_defaut: "",
                users: []
            },
            criterearticle: {
                famille: "",
                search: ""
            },
            showDetail: true,
            dateOptions: {
                format: 'DD/MM/YYYY',
            },
            filtreDate: {
                dateDebut: "",
                dateFin: ""
            },
            users_id: "",


        }

    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            // console.log(paginationData);
            this.remise_total = paginationData.total_somme.remise_total;
            this.montant_total = paginationData.total_somme.total_montant_avec_remise;
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            this.vuetable.moreParams.point_de_ventes_id = this.point_de_ventes_id;
            this.vuetable.moreParams.startDate = this.filtreDate.dateDebut;
            this.vuetable.moreParams.endDate = this.filtreDate.dateFin;
            this.vuetable.moreParams.users_id = this.users_id;
            Vue.nextTick(() => this.$refs.vuetable.refresh());

            // var that = this;
            // axios.post(that.BASE_URL + "/factures/fetchmontanttotaldetaiil", this.vuetable.moreParams).then(function(response) {
            //     console.log(response);
            //     that.montant_total = response.data[0].montantTotal;
            // });
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.filter = this.motCle;
            this.setFilter();
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("myCustomModal");
        },
        closeModal() {
            this.$bvModal.hide("myCustomModal");
        },

        loadPointDeVente() {
            axios.get(this.BASE_URL + "/pointvente/getall/").then(response => {
                //console.log(response.data[0]);
                this.point_de_ventes = response.data;
            });
        },
        fetchData: function() {
            axios.get(this.BASE_URL + "/factures/fetchdata").then(response => {
                this.listdata.familles = response.data.familles;
                this.listdata.magasins = response.data.magasins;
                this.listdata.users = response.data.users;
                this.listdata.magasin_defaut = response.data.magasin_defaut;



                if (this.listdata.magasin_defaut != '' && this.listdata.magasin_defaut != null) {
                    this.crudform.magasin_id = this.listdata.magasin_defaut + '';
                }
            });
        },
        startDownload() {
            this.$parent.showLoading = true;
        },
        finishDownload() {
            this.$parent.showLoading = false;
        },
        async fetchExport() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            this.vuetable.moreParams.point_de_ventes_id = this.point_de_ventes_id;
            this.vuetable.moreParams.startDate = this.filtreDate.dateDebut;
            this.vuetable.moreParams.endDate = this.filtreDate.dateFin;
            this.vuetable.moreParams.users_id = this.users_id;

            const response = await axios.get(this.exportexcel.exportLink, {
                params: this.vuetable.moreParams
            });
            return response.data;
        }


    },
    computed: {
        articlesList: function() {
            var that = this;
            return this.listdata.articles.filter(function(article) {
                if (article.ref_article.toLowerCase().includes(that.criterearticle.search.toLowerCase()) || article.designation.toLowerCase().includes(that.criterearticle.search.toLowerCase())) {
                    return true;
                }
                return false;
            });
        }
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);

        this.motCle = "";
        this.criteriacol = "";

        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.filtreDate.dateDebut = currentDateWithFormat;
        this.filtreDate.dateFin = currentDateWithFormat;

        this.fetchData();
        this.loadPointDeVente();
        this.setFilter();

    },
    watch: {
        'criterearticle.famille': function() {
            this.setCritereArticleFilter();
        },
        'crudform.magasin_id': function() {
            this.setCritereArticleFilter();
        },

    },
    mounted: function() {

    }
}